import { isClient, isFunction } from '@qctsw/utils'
import type { WatchStopHandle } from 'vue'

export function useActivated(handler: {
  activatedFn?: () => void
  deactivatedFn?: () => void
} = {}, options?: { mounted?: boolean }) {
  const { activatedFn, deactivatedFn } = handler
  const { mounted = true } = options || {}

  if (!activatedFn && !deactivatedFn)
    return

  if (activatedFn && isFunction(activatedFn)) {
    mounted && onMounted(activatedFn)
    onActivated(activatedFn)
  }

  if (deactivatedFn && isFunction(deactivatedFn)) {
    onUnmounted(deactivatedFn)
    onDeactivated(deactivatedFn)
  }
}

export function useActivatedWatch(watchFn: () => WatchStopHandle[], options: {
  onlyClient?: boolean
} = { onlyClient: true }) {
  const { onlyClient } = options

  if (onlyClient && !isClient)
    return
  let stops: WatchStopHandle[] | null = null
  function activatedHandler() {
    if (stops?.length)
      deactivatedHandler()

    if (!watchFn || !isFunction(watchFn))
      return

    stops = watchFn()
  }
  function deactivatedHandler() {
    stops?.forEach(stop => stop?.())
  }

  onMounted(() => {
    activatedHandler()
    onActivated(activatedHandler)
  })
  onUnmounted(deactivatedHandler)
  onDeactivated(deactivatedHandler)
}
